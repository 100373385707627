
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400&display=swap');

* {
  margin: 0;
  padding: 0;
} 

@tailwind base;
@tailwind components;
@tailwind utilities;

.nav-link-active {
  text-decoration: underline;
}

.logo-slider {
  position: relative;
  width: 100%;
  height: 100px; /* Adjust height as needed */
}

.logos-set {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 1.5s ease;
}

.logos-set.active {
  opacity: 1;
}

.image-container img {
  width: 200px; /* Adjust the size as needed */
  height: 100px; /* Adjust the size as needed */
  animation: moveImages 10s linear infinite; /* Adjust duration and timing function as needed */
}


