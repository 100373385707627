.changing-paragraph {
    font-size: 18px; /* Adjust as needed */
    line-height: 1.5;
    margin: 0;
    transition: opacity 0.5s ease; /* Add transition effect */
    font-weight: 800;
  }

  .changing-paragraph.fade-in {
    opacity: 1;
  }
  
  /* Fade-out animation */
  .changing-paragraph.fade-out {
    opacity: 0;
  }

  .testimonial-slider {
    overflow: hidden;
    width: 100%;
    height: 400px; /* Adjust height as needed */
    position: relative;
  }
  
  .testimonial {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
  }
  
  .testimonial.active {
    opacity: 1;
  }
  
  